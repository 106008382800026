const list = [{
    name: "控制台",
    path: "/control",
    index: "1",
    icon: "el-icon-s-platform"
  },
  {
    name: "订单管理",
    index: "3",
    path: "",
    icon: "el-icon-s-order",
    children: [{
        name: "初审列表",
        path: "/order/firstCheck",
        index: "3-1"
      },
      {
        name: "复审列表",
        path: "/order/secondCheck",
        index: "3-2"
      },
      {
        name: "待还列表",
        path: "/order/unpaid",
        index: "3-3"
      },
      {
        name: "回款记录",
        path: "/order/paid",
        index: "3-4"
      },
      {
        name: "已拒订单",
        path: "/order/refused",
        index: "3-5"
      },
      {
        name: "续期记录",
        path: "/order/renewal",
        index: "3-6"
      }
    ]
  },
  {
    name: "信用速查",
    path: "/risk",
    index: "4",
    icon: "el-icon-s-marketing"
  },
  {
    name: "黑名单库",
    path: "/blacklist",
    index: "6-3",
    icon: "el-icon-s-custom"
  },
  {
    name: "财务统计",
    index: "5",
    path: "",
    icon: "el-icon-s-finance",
    children: [
      {
        name: "查询明细",
        path: "/finance/detail",
        index: "4-2"
      }
      // {
      //   name: "消费统计",
      //   path: "/finance/consume",
      //   index: "4-3"
      // }
      // {
      //   name: "真假财务",
      //   path: "/finance/really",
      //   index: "4-4"
      // }
    ]
  },
  {
    name: "账号管理",
    index: "6",
    path: "",
    icon: "el-icon-s-management",
    children: [{
        name: "角色管理",
        path: "/userManager",
        index: "6-1"
      },
      {
        name: "账号列表",
        path: "/account",
        index: "6-2"
      }
    ]
  },
  {
    name: "更新记录",
    path: "/updateRecord",
    index: "9",
    icon: "el-icon-date"
  },
  {
    name: "操作手册",
    path: "/manual",
    index: "10",
    icon: "el-icon-document"
  },
];

export default {
  list
};
