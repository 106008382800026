<template>
  <div class="header-box fx-v-center" style="justify-content: space-between; ">
    <div class="fx-v-center hidden-xs-only">
      <el-breadcrumb separator-class="el-icon-arrow-right" class="user-center-bread" v-if="breadList.length"
        style="color: #000000">
        <el-breadcrumb-item v-for="(item, index) in breadList" :key="index" :to="{ path: item.path }">{{ item.name
          }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <!-- <div class="header-box-fold hidden-xs-only" >
      <span class="ft-sz-22 el-icon-s-fold" title="折叠" @click="$emit('switch-collapse')"></span>
    </div> -->
    <!-- <div class="tip hidden-xs-only" v-if="balance < 100 && user.roleId !== 100">温馨提示:您的账户余额已不足100元,请尽快安排充值,以免影响使用!</div> -->
    <!-- 头像下拉表 -->
    <div style="display: flex; align-items: center;">

      <div>
        <!-- <div class="user-left hidden-xs-only">
        <span class="ft-bold ft-sz-18">你好, {{ user.username || "" }}</span>
        <p>{{ user.mobile || "************" }}</p>
      </div> -->
        <!-- <span class="money hidden-sm-and-up" @click="showMoney()"
            ><img src="@/assets/h5img/money.png" alt=""
          /></span> -->
        <div class="ft-sz-15">
          <div class="default tips" @click="toCharge" v-if="!isPreview">充值</div>
          <div class="default">账户余额 {{ balance }} 元</div>
          <!-- <div class="default" v-if="pro" style="margin-left: 25px">
            剩余星币 {{ promoteCoin }} 个
          </div> -->
        </div>
      </div>

      <div class="header-box-drop">
        <el-dropdown trigger="hover" class="user-name" @command="handleCommand">
          <div class="avatar fx-v-center ft-sz-15" style="color: #000; font-weight: 500;">
            <span class="hidden-xs-only">个人中心</span>
            <i class="el-icon-arrow-down el-icon--right hidden-xs-only"></i>
            <span class="person hidden-sm-and-up"><img src="@/assets/h5img/penson.png" alt=""></span>
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="reset">账户信息</el-dropdown-item>
            <el-dropdown-item command="logout">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <info ref="resetRef"></info>
    </div>
    <!-- <div class="tip ft-bold hidden-sm-and-up" v-if="balance < 100 && user.roleId !== 100">温馨提示:您的账户余额已不足100元,请尽快安排充值,以免影响使用!</div> -->
  </div>
</template>

<script>
import info from "./info"

export default {
  components: { info },
  computed: {
    merchantName() {
      let name = sessionStorage.getItem("merchant_name");
      return name || "商户";
    },
    // 余额
    balance() {
      return this.$store.state.balance || 0;
    },
    user() {
      return this.$store.state.XINYOU_SH_USER || {};
    },
    isChannel() {
      return this.$store.state.XINYOU_SH_USER.roleId === 100;
    },
    promoteCoin() {
      return this.$store.state.promoteCoin || 0;
    },
    // 面包屑导航
    breadList() {
      let defaultName = sessionStorage.getItem("merchant_name");
      let list = [{ path: "/", name: defaultName }].concat(
        this.$route.meta.bread
      );
      return this.$route.meta.bread ? list : [];
    }
  },
  data() {
    return {
      isPreview: false,
      seconds: 0,
      captcha: false,
    };

  },
  methods: {
    handleCommand(command) {
      switch (command) {
        case "logout":
          // sessionStorage.removeItem('xinyou_sh_user');
          // sessionStorage.removeItem('token');
          sessionStorage.clear();
          this.$router.replace({ path: "/login" });
          break;
        case "reset":
          this.$refs.resetRef.openReset()
          break;
        case "money":
          this.
            break;
      }
    },
    // 点击图标回首页
    toIndex() {
      this.$router.push({ path: "/" });
    },
    close() {
      this.dialogFormVisible = false;
      this.$refs.form.resetFields();
    },
    toCharge() {
      this.$router.push({ path: '/charge' });
    },
    showMoney() {
      let a = this.balance > -999 && !this.isChannel;
      this.$alert(
        '<div class="user-right ft-sz-14" v-if="' +
        a +
        '">' +
        '<div class="default user-right-balance">账户余额 ' +
        this.balance +
        "元</div>" +
        '<div class="default user-right-balance" v-if="' +
        this.pro +
        '">剩余星币 ' +
        this.promoteCoin +
        "个</div>" +
        "</div>",
        "余额及充值",
        {
          dangerouslyUseHTMLString: true,
          confirmButtonText: "充值",
        }
      ).then(() => {
        this.toCharge();
      });
    },
  },
  created() {
    if (document.documentElement.clientWidth < 480) {
      this.width = '80%'
    }
    this.isPreview = location.hostname.indexOf("preview") != -1;
  }
};
</script>

<style lang="scss" scoped>
.header-box-drop {
  position: relative;
  right: 10px;
  z-index: 999;
  top: 0;
  margin-left: 30px;
}

.default {
  display: inline-block;
  color: #000;
  font-weight: 500;
}


.hidden-sm-and-up {
  display: block;
  background-color: #fff;
}

.hidden-sm-and-up span {
  margin-left: 30px;
}

.header-box {
  // height: 70px;
  padding: 0 60px;
  // border-bottom: 1px solid #e2e8f0;
  box-sizing: border-box;
  //background-color: #fff;
  line-height: 70px;

  &-logo {
    min-width: 250px;
    margin-right: 20px;

    &-name {
      color: #2b65b1;
      margin-left: 20px;
      letter-spacing: 4px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .line {
      margin-right: 10px;
    }
  }

  &-fold {
    margin-left: 10px;
  }

  .tip {
    color: #fff;
    margin-left: 8px;
    font-size: 15px;
  }

  .tips {
    color: #f05b06;
    margin: 0 25px;
    cursor: pointer;
    font-weight: bold;
  }

  .chongzhi {
    height: 36px;
    line-height: 36px;
    border-radius: 5px;
    padding: 0 20px;
    cursor: pointer;
    display: inline-block;
    background-color: #f93b7a;
    border: 1px solid #f93b7a;
    margin-right: 10px;
    color: #ffffff;
    margin-left: 10px;
  }
}

@media screen and (max-width: 767px) {
  .header-box-drop {
    position: absolute;
    right: 25px;
    z-index: 999;
    margin-top: -8px;
  }

  .indextop {
    background-color: #fff;
    padding-left: 30px;

    span {
      display: inline-block;

      img {
        width: 30px;
        height: 30px;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .header-box {
    line-height: 3rem;
  }

  .header-box .tip {
    font-size: 10px;
    height: auto;
    line-height: 1rem;
    width: 100%;
    background: #fe0101;
    color: #fff;
    margin-left: 0;
    padding: 0.3rem 1rem;
  }

  .default {
    // font-weight: bold;
    margin-left: 10px;
    font-weight: bold;
    // display: inline-block;
  }

  .tips {
    color: #fe0101 !important;
  }
}
</style>
